import React from "react"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

const Digitalu = ({ data }) => {
  const {
    strapiDigital: { title, image, info, topic },
  } = data
  return (
    <>
      <Layout>
        <Seo
          title="Digital-U"
          description="This is our Digital University page"
        />
        <section className="about-page">
          <div className="section-center about-center">
            <img
              src={image.localFile.url}
              alt={title}
              className="about-img-svg"
            />
            {/* <img src={image.publicURL} alt={title} className="about-img-svg" /> */}
            <article className="about-text">
              <Title title={title} />
              <p>{info}</p>
              <div className="about-stack">
                {topic.map(item => {
                  return <span key={item.id}>{item.detail}</span>
                })}
              </div>
            </article>
          </div>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    strapiDigital {
      topic {
        detail
        id
      }
      image {
        localFile {
          url
        }
      }
      title
      id
      info
    }
  }
`

export default Digitalu
